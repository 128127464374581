import ApplicationController from './application_controller'
import { compact } from 'lodash'

export default class TableSelectionController extends ApplicationController {
  static targets = ['row', 'ids', 'selectAll']
  idsSet = new Set()

  connect () {
    super.connect()
    this.loadIdsFromForm()
  }

  rowTargetConnected (target) {
    this.setCheckboxState(target)
  }

  updateSelectAll () {
    if (this.selectAllTarget.checked) {
      for (const row of this.rowTargets) {
        this.idsSet.add(parseInt(row.value))
        row.checked = true
      }
    } else {
      for (const row of this.rowTargets) {
        this.idsSet.delete(parseInt(row.value))
        row.checked = false
      }
    }
    this.serializeIdsToForm()
  }

  setSelectAllState () {
    for (const row of this.rowTargets) {
      if (!this.idsSet.has(parseInt(row.value))) {
        this.selectAllTarget.checked = false
        return
      }
    }
    this.selectAllTarget.checked = true
  }

  updateRowSelect (event) {
    const id = parseInt(event.target.value)
    if (event.target.checked) {
      this.idsSet.add(id)
    } else {
      this.idsSet.delete(id)
    }
    this.setSelectAllState()
    this.serializeIdsToForm()
  }

  loadIdsFromForm () {
    const ids = compact(this.idsTarget.value.split(',').map((value) => parseInt(value)))
    this.idsSet = new Set(ids)
  }

  setCheckboxState (checkbox) {
    checkbox.checked = this.idsSet.has(parseInt(checkbox.value))
    this.setSelectAllState()
  }

  serializeIdsToForm () {
    this.idsTarget.value = [...this.idsSet].join(',')
  }
}
